<template>
  <div>
    <upload-file-form
      ref="uploadArquivo"
      v-if="!!uploadFileUrl"
      :title="$t('label.fazer_nova_importacao') "
      :auto-process-queue="false"
      @UploadArquivo__success="uploadSuccess"
      @UploadArquivo__error="uploadError"
      @UPLOADFILE_SENDING="enviandoArquivo"
      :maxFileSizeInMB="50"
      :url="uploadFileUrl"
      :accepted-file-types="tipoImportacaoSellin.arquivosSuportados"
      :custom-validate="validaMesesImportacao"
      >
      <v-date-picker
        locale="pt-br"
        v-model="mesesImportacao"
        type="month"
        no-title
        color="primary"
        multiple/>
    </upload-file-form>

    <v-dialog v-model="exibeModalExportacao" content-class="NotasExportacao__SelecaoPeriodo">
      <v-card fluid v-if="!!itemExportacao">
        <v-radio-group
          v-model="tipoData"
          class="ma-0 ml-4 pa-2"
          hide-details
          :label="$t('label.aplicar_se_a')"
          v-if="itemExportacao.tipo !== 'SELLOUT'">
          <v-radio
            color="primary"
            :label="$t('label.data_conferencia')"
            value="dataConferencia" v-if="indModoVarejo">
          </v-radio>
          <v-radio
            color="primary"
            :label="$t('label.data_emissao_exportacao')"
            value="dataEmissao">
          </v-radio>
        </v-radio-group>
        <v-date-picker
          locale="pt-br"
          v-model="mesesExportacao"
          type="month"
          no-title
          color="primary"
          multiple>
          <v-spacer></v-spacer>
          <v-btn text color="primary"
            @click.native="fecharModalCalendario(itemExportacao.ordenacao - 1)">
              {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn text color="primary"
            @click.native="abrirModalConfirmacao('exportar', itemExportacao)">
            {{ $t('label.confirmar') }}
          </v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>

    <v-container fluid px-0 py-2 class="Container_Nivel1_Notas">
      <v-data-iterator
        :items="listaCards"
        hide-default-footer
        v-show="!esperar"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [5],
        }">
        <template v-slot:default="props">
          <v-row justify="center">
            <v-col
              v-for="item in props.items"
              :key="item.ordenacao"
              cols="12"
              sm="6"
             md="4"
              lg="3"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card :class="`elevation-${hover ? 5 : 1}`" class="clickable Card_Notas fill-height">
                  <v-card-title class="Card_Title pa-2" @click="selectItem(item)">
                    <p>{{ item.titulo }}</p>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text @click="selectItem(item)" class="Card_Content pa-0">
                    <v-row justify="center" align="center">
                      <v-col cols="12">
                        <p>
                          <span class="subheading">{{ $t('label.valor_bruto') }}</span> <br />
                          <span class="title">{{ getMoney(item.valorTotal) }}</span>
                        </p>
                        <p>
                          <span class="subheading">{{ $t('label.valor_liquido') }}</span> <br />
                          <span class="title">{{ getMoney(item.valorLiquido) }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="Card_Actions">
                    <v-row justify="space-around">
                      <v-col cols="2" class="text-center" v-if="canAccessCRUD">
                        <v-tooltip bottom v-if="exibirExportacao(item)">
                          <template v-slot:activator="{ on }">
                            <v-btn icon
                                   v-on="on"
                                   @click="() => $refs.uploadArquivo.open()">
                              <v-icon>
                                cloud_upload
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('label.importar') }}</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="2" class="text-center">
                        <v-tooltip bottom v-if="exibirExportacao(item)">
                          <template v-slot:activator="{ on }">
                            <v-btn icon
                                   v-on="on"
                                   @click="exibeDialogExportacao(item)">
                              <v-icon>
                                get_app
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('label.exportar') }}</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="2" class="text-center">
                        <v-tooltip bottom v-if="exibirExportacao(item)">
                          <template v-slot:activator="{ on }">
                            <v-btn icon
                                   v-on="on"
                                   @click="exportaModelo(item)">
                              <v-icon>
                                font_download
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('label.exportar_modelo') }}</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="2" class="text-center" v-if="!exibirExportacao(item)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              slot="activator"
                              color="warning"
                              class="mx-3">
                              error_outline
                            </v-icon>
                          </template>
                          <span>{{ item.qtd }} {{ $tc('label.inconsistencia', 2) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="confirmaExportacao"
      @disagree="cancelarExportacao">
    </confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMoney } from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

import { basePath } from '../../common/resources/importacoes';
import UploadFileForm from '../../shared-components/upload/UploadFileForm';

export default {
  name: 'NotasNivel1DadosAgrupado',
  components: {
    Confirm,
    UploadFileForm,
  },
  props: {
    exibeImportador: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resources: this.$api.notas(this.$resource),
      listaCards: [],
      rowsPerPageItems: [5],
      esperar: false,
      menu: [
        false,
        false,
        false,
      ],
      date: new Date().toISOString().substr(0, 10),
      mensagemConfirmacao: '',
      bloquearBotaoExportacao: false,
      notaSelecionada: {},
      mesesExportacao: [],
      mesesImportacao: [],
      tipoData: this.indModoVarejo ? 'dataConferencia' : 'dataEmissao',
      abrirImportacao: false,
      exibeModalExportacao: false,
      itemExportacao: null,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('NOTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacaoSellin() {
      return this.tiposImportacao.filter((t) => t.nome.indexOf('sellin') >= 0)[0];
    },
    uploadFileUrl() {
      return this.tipoImportacaoSellin
        ? `${basePath}importacao`
        : null;
    },
  },
  methods: {
    getMoney,
    exibirExportacao(nota) {
      if (nota.tipo === 'INCONSISTENCIA') {
        return false;
      }
      return true;
    },
    validaMesesImportacao() {
      if (this.mesesImportacao.length === 0) {
        this.$toast(this.$t('message.mes_importacao_obrigatorio'), 'bottom', 8E3);
        return false;
      }
      return true;
    },
    enviandoArquivo(data, xhr, formData) {
      const parametros = { meses: this.mesesImportacao };
      formData.append('id_tipo_importacao', this.tipoImportacaoSellin.id);
      formData.append('parametros', JSON.stringify(parametros));
    },
    exibeDialogExportacao(item) {
      this.itemExportacao = item;
      this.exibeModalExportacao = true;
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
    },
    uploadSuccess() {
      this.$toast(this.$t('message.importado_em_breve_sera_processado'), 'bottom', 8E3);
      this.mesesImportacao = [];
      this.$refs.uploadArquivo.close();
    },
    selectItem(item) {
      this.$emit('Notas__SelecionaItemNivel1', item);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    buscar() {
      this.resources.listarNotasAgrupadasPorTipo().then((response) => {
        this.listaCards = response.data;
        this.pararEsperar();
        this.irParaNivel2();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    irParaNivel2() {
      const { irPara } = this.$route.query;
      if (irPara) {
        const item = this.listaCards.find((c) => c.tipo === irPara);
        this.selectItem(item);
      }
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    abrirModalConfirmacao(acao, nota) {
      this.exibeModalExportacao = false;

      if (acao !== 'exportar') {
        return;
      }
      if (!this.mesesExportacao.length) {
        this.$toast(this.$t('errors.periodo.obrigatorio'));
        return;
      }
      this.bloquearBotaoExportacao = true;
      this.notaSelecionada = nota;
      this.mensagemConfirmacao = this.$t('message.deseja_exportar');
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    fecharModalCalendario(index) {
      this.menu[index] = false;
      this.exibeModalExportacao = false;
      this.$forceUpdate();
    },
    exportaModelo(item) {
      this.notaSelecionada = item;
      this.$emit('TABELA_EXPORTAR_MODELO', this.notaSelecionada.tipo);
    },
    confirmaExportacao() {
      const tipoNota = this.notaSelecionada.tipo;
      const meses = this.mesesExportacao
        .map((m) => parseInt(m.split('-').join(''), 10));
      const { tipoData } = this;

      this.$toast(this.$t('message.exportacao_download'));
      this.resources.verificarDadosExportacao({ tipoNota, meses, tipoData }).then((response) => {
        this.pararEsperar();
        if (response.body) {
          this.$emit('TABELA_EXPORTAR_DADOS',
            () => { this.bloquearBotaoExportacao = false; },
            this.notaSelecionada, meses, this.tipoData);
        } else {
          this.bloquearBotaoExportacao = false;
          this.$toast(this.$t('message.sem_dados_exportacao_periodo'));
        }
      }).catch((err) => {
        this.pararEsperar();
        this.$error(this, err);
      }).finally(() => {
        this.bloquearBotaoExportacao = false;
        this.mesesExportacao = [];
      });
    },
    cancelarExportacao() {
      this.bloquearBotaoExportacao = false;
      this.notaSelecionada = {};
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
      return;
    }
    this.filtrar();
  },
};
</script>

<style>
.Container_Nivel1_Notas .Container_Cards {
  padding: 1em 4px 7px 4px;
}
.Container_Nivel1_Notas .Card_Recebimento {
  min-height: 9.5em;
}
.Container_Nivel1_Notas .Card_Title, .Container_Nivel1_Notas .Card_Content {
  color: #757575;
  text-align: center;
  display: block;
  padding: 0;
}
.Container_Nivel1_Notas .Card_Title p {
  font-size: 1.1em;
  font-weight: 500;
  padding: 0;
  margin: 0px;
}
.Container_Nivel1_Notas .Card_Content p {
  font-size: 1.5em;
  font-weight: 300;
  padding: 0;
  margin: 0px;
}
.Container_Nivel1_Notas .Card_Actions {
  min-height: 1em;
}
.Container_Nivel1_Notas .Card_Actions button, .Container_Nivel1_Notas .Card_Actions span {
  min-width: 12%;
  width: 25px;
  font-size: 18px;
  text-align: center;
}
.Container_Nivel1_Notas .Card_Actions button {
  color: dimgray;
}
.clickable {
  cursor: pointer;
}
.botaoInconsistencia {
  height: 2em !important;
  width: 2em !important;
  font-size: small !important;
  color: white !important;
}
.NotasExportacao__SelecaoPeriodo {
  width: auto;
}
</style>
